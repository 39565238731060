import React from "react";
import { Link, navigate } from "gatsby";
import { Portal } from "react-portal";

import { Root, Container, NavList, NavLink } from "./MobileMenu.style";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";

import SocialList from "../SocialList/SocialList";

const MobileMenu = ({ onClose, isSlim }) => {
  useLockBodyScroll();

  return (
    <Portal>
      <Root $isSlim={isSlim}>
        <Container>
          <NavList>
            <li>
              <NavLink as={Link} to="/" activeClassName="--active" onClick={onClose}>
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/#product" activeClassName="--active" onClick={onClose}>
                PRODUCT
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/history" activeClassName="--active" partiallyActive={true} onClick={onClose}>
                HISTORY
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/recipes" activeClassName="--active" partiallyActive={true} onClick={onClose}>
                RECIPES
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/shop" activeClassName="--active" partiallyActive={true} onClick={onClose}>
                SHOP
              </NavLink>
            </li>
            <li>
              <SocialList />
            </li>
          </NavList>
        </Container>
      </Root>
    </Portal>
  );
};

export default MobileMenu;
