import React from "react";

import { Root, Item, Img } from "./SocialList.style";

import InstagramSrc from "../../images/instagram.svg";
import FacebookSrc from "../../images/facebook.svg";
import TwitterSrc from "../../images/twitter.svg";
import YoutubeSrc from "../../images/youtube.svg";

const SocialList = () => {
  return (
    <Root>
      <Item>
        <a href="https://www.instagram.com/jamesownbyreserve/" target="_blank">
          <Img src={InstagramSrc} alt="Instagram" />
        </a>
      </Item>
      <Item>
        <a href="https://www.facebook.com/JamesOwnbyReserve/" target="_blank">
          <Img src={FacebookSrc} alt="Facebook" />
        </a>
      </Item>
    </Root>
  );
};

export default SocialList;
