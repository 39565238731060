import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  color: #d79d5c;
  background-color: #141f35;
  font-family: "GreatWestern";
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  pointer-events: ${p => !p.isVisible && 'none'};
  z-index: 10000;

  @media (max-width: 1023px) {
    display: none;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 16px;
    left: 16px;
    height: 1px;
    background-color: #d79d5c;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 8px;
  cursor: pointer;

  &.--link {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:hover,
  &.--active {
    color: #e9dfd0;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0 none;

  * {
    pointer-events: none;
  }
`;

const DropdownPortal = ({
  triggerContent,
  dropdownContent,
  placement,
  offset,
  label,
}) => {
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      strategy: 'fixed',
      placement,
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset,
          },
        },
      ],
    },
  );

  const handleDocumentClick = e => {
    if (
      e.target === referenceRef.current ||
      popperRef.current.contains(e.target)
    ) {
      return;
    }
    setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => document.removeEventListener('mousedown', handleDocumentClick);
  });

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <Button
        className="referenceRef"
        type="button"
        aria-haspopup="true"
        aria-expanded={isVisible}
        aria-label={label}
        onClick={toggleVisibility}
        ref={referenceRef}
      >
        {triggerContent}
      </Button>
      <Portal>
        <Dropdown
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          isVisible={isVisible}
        >
          {dropdownContent({ isVisible, toggleVisibility })}
        </Dropdown>
      </Portal>
    </>
  );
};

DropdownPortal.Item = Item;

DropdownPortal.propTypes = {
  triggerContent: PropTypes.node.isRequired,
  dropdownContent: PropTypes.func.isRequired,
  placement: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
  label: PropTypes.string,
};

DropdownPortal.defaultProps = {
  placement: 'bottom-end',
  offset: [0, 8],
  label: 'Actions menu',
};

export default DropdownPortal;
