import styled from "styled-components";

export const Root = styled.div`
  position: fixed;
  top: ${p => p.$isSlim ? '48px' : '66px'};
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  background-color: #141f35;
  border-top: 1px solid #02040b;
  z-index: 2000;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1367px;
  list-style: none;
`;

export const NavLink = styled.a`
  color: #d79d5c;
  font-family: "GreatWestern";
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
