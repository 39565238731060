import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Root,
  Status,
  Input,
  Submit,
} from "./SubscribeForm.style";

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SubscribeForm = ({ placeholder = "Subscribe", submitText = "Submit", isShop = false }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [emailAddress, setEmailAddress] = useState("");
  const [formStatus, setFormStatus] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    if (emailAddress == null || emailAddress === '') return;
    if (!emailPattern.test(emailAddress)) {
      setFormStatus("Invalid Email.");
      return;
    }
    if (!executeRecaptcha) {
      setFormStatus("Invalid reCAPTCHA. Please try again.");
      return;
    }
    const captchaToken = await executeRecaptcha("subscribe");

    try {
      setFormStatus("Submitting...");
      const response = await fetch(
        `https://${process.env.GATSBY_API_DOMAIN}/subscribe`,
        {
          method: "POST",
          body: JSON.stringify({ emailAddress, captchaToken }),
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      console.info({ response });
      setEmailAddress("");
      setFormStatus("Success signing up!");
    } catch (err) {
      const errorJson = err.responseJSON;
      if (
        ["INVALID_CAPTCHA", "INVALID_CAPTCHA_SCORE"].includes(errorJson.type)
      ) {
        setFormStatus("Invalid captcha. Please try again.");
      } else {
        setFormStatus("An error occurred. Please try again.");
      }
    }
  };

  const handleFocus = () => {
    const recaptchaEl = document.getElementsByClassName("grecaptcha-badge");
    recaptchaEl[0].style.visibility = "visible";
  };

  return (
    <Root onSubmit={handleSubmit}>
      <Status $isShop={isShop}>{formStatus}</Status>
      <Input
        type="text"
        name="subscribe"
        placeholder={placeholder}
        value={emailAddress}
        onChange={e => setEmailAddress(e.target.value)}
        onFocus={handleFocus}
      />
      <Submit type="submit" $isShop={isShop}>{submitText}</Submit>
  </Root>
  )
}

export default SubscribeForm
