import styled from "styled-components";

export const Root = styled.ul`
  display: flex;
`;

export const Item = styled.li`
  &:not(:last-of-type) {
    margin-right: 28px;
  }
`;

export const Img = styled.img`
  height: 22px;
`;
